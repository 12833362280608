.form-heading
{
  margin-top: -2px !important;
    position: absolute;
    background: #ffffff;
    width: 200px;
}
.sec-spacing
{
    border: 1px solid #eee;
    padding: 30px 0px;
}
.modal-footer
{
    border-top:0px Im !important;
}
.col-form-label
{
    font-weight: 500;
}
.chekbox{
    cursor:pointer;
  }